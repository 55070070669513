import React, { ReactElement } from "react";
import dynamic from "next/dynamic";

import MainBanner from "@components/forkliftestimates/mainBanner/mainBanner";
import { GetServerSideProps } from "next";

import ForkliftEstimatesWrapper from "@components/layouts/forkliftestimatesWrapper";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
const WhyUs = dynamic(() => import("@components/forkliftestimates/whyUs"));
const OffersList = dynamic(() => import("@components/shared/offersList"));
export default function Home(): ReactElement {
    const { domain } = useDomainContext();
    return (
        <ForkliftEstimatesWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
        </ForkliftEstimatesWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
